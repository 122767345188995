<template>
  <div class="px-5" id="page-public-event-category">
    <!-- Loader and error handling -->
    <b-card v-if="loading">
      <b-card-body style="text-align: center;" >
          <b-row class="justify-content-md-center">
            <b-col col lg="2"></b-col>
            <b-col cols="12" md="auto">
              <div v-if="show_error" class="ml-8 alert alert-custom alert-notice alert-light-danger fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{{$t('COMMON.TRY_AGAIN_LATER')}} <strong>{{errorMessage}}</strong></div>
              </div>
              <div v-else class="spinner spinner-track spinner-primary spinner-lg mr-15"></div>
            </b-col>
            <b-col col lg="2"></b-col>
          </b-row>
      </b-card-body>
    </b-card>
    <!-- End loader and error handling -->

    <!-- begin terms modal -->

    <b-modal v-if="term" ref="termsModal" hide-footer>
      <b-row>
        <b-col lg="12">
          <div v-html="term.template.template">

          </div>
        </b-col>
      </b-row>
      <b-row class="mt-8 mb-8">
        <b-col lg="6">

        </b-col>
        <b-col lg="6">
          <b-button class="" variant="outline-secondary" block @click="onCancelTermModal"
            >{{$t('COMMON.CLOSE')}}</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <!-- end terms modal -->


    <!-- Actual content -->
    <b-row v-if="!loading">
      <!-- Left main content -->
      <b-col lg="8" xl="8" cols="12" class="mb-8">
        <b-card>
          <b-card-body class="event-html" v-html="event_category.html"></b-card-body>
        </b-card>
      </b-col>
      <!-- Content to the right side -->
      <b-col lg="4" xl="4" cols="12" class="mb-8">
        <b-card
          :title="event_category.header"
        >
          <p style="font-weight: bold;">{{$t('PAGES.BOOKING.SELECT_BOOKING')}}</p>

          <hr/>

          <template v-for="(item, i) in event_category.events">
            <EventSelectableBox
              v-bind:key="i"
              :item="item"
              @change="onSelectedEventChanged" />
          </template>

        </b-card>

        <div v-if="selected_event" class="mt-8" id="scroll-to-event">
          <google-map
            v-if="selected_event.type !== 'ONL'"
            :center="{ lat: selected_event.google_maps.lat, lng: selected_event.google_maps.lng }"
            :zoom="16"
            style="height: 256px"
            id="map"
            ref="Map"
            class="shadow-sm p-2 mb-5 rounded"
          >
            <google-map-marker
              :position="{ lat: selected_event.google_maps.lat, lng: selected_event.google_maps.lng }"
            ></google-map-marker>
          </google-map>


          <b-card v-if="selected_event" class="mb-6">
            <h5 v-html="selected_event.name"></h5>
            <p style="color: #888888;" v-html="selected_event.location_descr"></p>
            <p v-html="selected_event.descr_html"></p>
          </b-card>

          <b-card
            :title="$t('COMMON.INVOICE_INFORMATION')">
            <InvoicePerson
              ref="invoicePerson"
              @change="invoicePersonChanged"
            />
          </b-card>

<!--
          <SmallMemberLoggedinInfo
            v-if="currentMember"
            class="mb-6"
          />
-->
<!--
          <Login
            v-if="!currentMember"

            ref="loginForm"
            class="mb-6"

            :company_id="selected_event.company_id"
          />-->

          <MemberToEventList
            class="mt-4"
            @onValidationMembers="onValidationMembers"
          />

          <b-card class="mt-4">
            <b-row>
              <b-col>

                 <template v-for="(item, i) in event_category.terms">

                    <TermsAndConditionsCheckbox
                      v-bind:key="i"
                      :term_id="item.id"
                      :text="item.descr"
                      :is_mandatory="item.is_mandatory"
                      @selected="termsChanged"
                      @showTerms="showTerms"
                    />

                </template>

              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button :disabled="disabled_continue" class="mt-6" variant="success" @click="onContinueEventRegistration()">{{$t('PAGES.BOOKING.CONFIRM_BOOKING')}}</b-button>
              </b-col>
            </b-row>

          </b-card>

        </div>

      </b-col>
    </b-row>
    <!-- End content -->

  </div>
</template>

<style lang="scss" scoped>

.event-html :deep li {

  margin-left: 20px;

}

.event-html :deep h2 {

  margin-top: 20px;
  margin-bottom: 10px;

}


</style>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import Login from '@/view/pages/members/auth/Login.vue';
import SmallMemberLoggedinInfo from '@/view/pages/members/loggedin/SmallMemberLoggedinInfo.vue';
import EventList from '@/view/pages/members/public/EventList.vue';
import EventSelectableBox from '@/view/pages/members/public/event_category_list/EventSelectableBox.vue';
import MemberToEventList from '@/view/pages/members/public/event_category_list/MemberToEventList.vue';
import InvoicePerson from '@/view/pages/members/public/event_category_list/InvoicePerson.vue';
import TermsAndConditionsCheckbox from '@/view/components/TermsAndConditionsCheckbox.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'event-page',
  components: {
    Login,
    EventList,
    EventSelectableBox,
    MemberToEventList,
    SmallMemberLoggedinInfo,
    InvoicePerson,
    TermsAndConditionsCheckbox
  },
  computed: {
    ...mapGetters(['currentMember']),
    disabled_continue() {
      return !this.terms_valid || !this.members_valid || !this.invoice_valid;
    }
  },
  mixins: [ toasts ],
  mounted() {

    this.event_category_id = this.$route.params.id;

    const id = parseInt(this.event_category_id);

    if (!this.event_category_id || isNaN(id)) {
      this.show_error = true;
      return;
    }

    this.loadCategoryData(this.event_category_id);
  },
  data() {
    return {
      invoice_person: {},
      errorMessage: '',
      term: null,
      selected_event: null,
      event_category_id: null,
      event_category: {},
      loading: true,
      show_error: false,
      show_continue: false,
      members_valid: false,
      invoice_valid: false,
      terms_valid: false,
      members: [],
      terms: []
    };
  },
  watch: {
  },
  methods: {
    onContinueEventRegistration() {
      const data = {
        persons: this.members,
        invoice_person: this.invoice_person,
        event_category_id: this.event_category_id,
        event_id: this.selected_event.event_id
      };

      this.postEventNonMembers(this.event_category_id, data)
      .then(result => {
        this.showSuccessAndRedirectAfterSeconds(5, this.event_category.redirect_url);
      })
      .catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.TRY_AGAIN_LATER'));
      });


    },
    onCancelTermModal() {
      this.$refs['termsModal'].hide();
    },
    termsChanged(term_id, val) {
      const t = this.terms.find(item => item.id === term_id);
      t.valid = val;

      const n = this.terms.find(item => !item.valid && item.is_mandatory);

      this.terms_valid = n === undefined;

      console.error('terms_valid', this.terms_valid);
    },
    invoicePersonChanged() {
      const validInvoicePerson = this.$refs['invoicePerson'].validateAll();
      this.invoice_person = this.$refs['invoicePerson'].getForm();

      this.invoice_valid = validInvoicePerson;
    },

    showTerms(term_id) {

      const t = this.event_category.terms.find(item => item.id === term_id);

      this.term = t;

      this.$nextTick(() => {
        this.$refs['termsModal'].show();
      });
    },
    onValidationMembers(members, valid) {
      //this.show_continue = valid;
      //this.members = members;

      if (valid) {
        this.members = members;
      }

      this.members_valid = valid;
    },
    onSelectedEventChanged(event_id) {
      const id = parseInt(event_id+'');

      if (isNaN(id)) {
        console.error('invalid id');
        return;
      }

      const selected_event = this.event_category.events.find(item => item.event_id === id);

      if (!selected_event) {
        console.error('evenemanget hittades ej');
        return;
      }

      this.selected_event = selected_event;

      this.$nextTick(()=>{
        this.scrollToEvent();

        if (this.$refs['loginForm']) {
          this.$refs['loginForm'].forceCompanyId(this.selected_event.company_id);
        }
      })
    },

    loadCategoryData(id) {
      axios
        .get(`/event_category/public/${id}`)
        .then(res => {
          if (res.status === 200) {
            this.event_category = res.data;

            if (!this.event_category.terms || this.event_category.terms.length === 0) {
              this.errorMessage = this.$t('PAGES.TERMS.MISSING');
              this.show_error = true;
              return;
            }

            for (const t of this.event_category.terms) {
              this.terms.push({ id: t.id, value: false, is_mandatory: t.is_mandatory });
            }

            const n = this.terms.find(item => item.is_mandatory);

            if (!n) {
              this.terms_valid = true;
            }

            this.loading = false;

          }
          else {
            this.show_error = true;
          }
        })
        .catch(err => {
          this.show_error = true;
          console.error(err);
        });
    },

    postEventNonMembers(event_category_id, data) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`/event_category/joinpersons/${event_category_id}`, data)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });
    },

    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    select_event_clicked() {},


    showSuccessAndRedirectAfterSeconds(seconds, href) {


      swal.fire({
        title: this.$t('PAGES.BOOKING.CREATED'),
        icon: 'success',
        html: this.$t('PAGES.BOOKING.SEND'),
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
      });


      setTimeout(function(){
        if (href.indexOf('http') !== -1) {
          location.href = href;
        }
        else {
          location.href = 'https://' + href;
        }

      }, seconds * 1000);
    },

    scrollToEvent() {
      var that = this;
      setTimeout(function () {
        const el = document.getElementById('scroll-to-event');

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
  }
};
</script>
